import { NFT_SERVER_BASE_URL } from "../network/baseUrl";

export enum NETWORK {
  ETHEREUM = 1,
  ETHEREUM_SEPOLIA = 11155111,
  POLYGON = 137,
  POLYGON_MUMBAI = 80001,
  AVALANCHE = 43114,
  AVALANCHE_FUJI = 43113,
  ARBITRUM_ONE = 42161,
  ARBITRUM_ONE_SEPOLIA = 421614,
  ARBITRUM_NOVA = 42170,
}

export const LGT_PROD_WALLET = '0xBdd87538521123603205C2D14cF566851f244983'

export const LGT_STAGING_WALLET = '0xaD6267A3de9d9B8B846FCA8b9C51A5b603f4dfc6'

export const LGT_WALLET_BY_CHAIN_ID = {
  [NETWORK.ETHEREUM]: LGT_PROD_WALLET,
  [NETWORK.ETHEREUM_SEPOLIA]: LGT_STAGING_WALLET,
  [NETWORK.POLYGON]: LGT_PROD_WALLET,
  [NETWORK.POLYGON_MUMBAI]: LGT_STAGING_WALLET,
  [NETWORK.AVALANCHE]: LGT_PROD_WALLET,
  [NETWORK.AVALANCHE_FUJI]: LGT_STAGING_WALLET,
  [NETWORK.ARBITRUM_ONE]: LGT_PROD_WALLET,
  [NETWORK.ARBITRUM_ONE_SEPOLIA]: LGT_STAGING_WALLET,
  [NETWORK.ARBITRUM_NOVA]: LGT_PROD_WALLET,
}

export const NFT_SERVER_BASE_URL_BY_CHAIN_ID = {
  [NETWORK.ETHEREUM]: NFT_SERVER_BASE_URL.PRODUCTION,
  [NETWORK.ETHEREUM_SEPOLIA]: NFT_SERVER_BASE_URL.STAGING,
  [NETWORK.POLYGON]: NFT_SERVER_BASE_URL.PRODUCTION,
  [NETWORK.POLYGON_MUMBAI]: NFT_SERVER_BASE_URL.STAGING,
  [NETWORK.AVALANCHE]: NFT_SERVER_BASE_URL.PRODUCTION,
  [NETWORK.AVALANCHE_FUJI]: NFT_SERVER_BASE_URL.STAGING,
  [NETWORK.ARBITRUM_ONE]: NFT_SERVER_BASE_URL.PRODUCTION,
  [NETWORK.ARBITRUM_ONE_SEPOLIA]: NFT_SERVER_BASE_URL.STAGING,
  [NETWORK.ARBITRUM_NOVA]: NFT_SERVER_BASE_URL.PRODUCTION,
}

export const NETWORK_NAME_BY_CHAIN_ID = {
  [NETWORK.ETHEREUM]: 'Ethereum',
  [NETWORK.ETHEREUM_SEPOLIA]: 'Ethereum Testnet',
  [NETWORK.POLYGON]: 'Polygon',
  [NETWORK.POLYGON_MUMBAI]: 'Polygon Testnet',
  [NETWORK.AVALANCHE]: 'Avalanche',
  [NETWORK.AVALANCHE_FUJI]: 'Avalanche Testnet',
  [NETWORK.ARBITRUM_ONE]: 'Arbitrum One',
  [NETWORK.ARBITRUM_ONE_SEPOLIA]: 'Arbitrum One Testnet',
  [NETWORK.ARBITRUM_NOVA]: 'Arbitrum Nova',
}

export const SIMPLE_HASH_NETWORK_BY_CHAIN_ID = {
  [NETWORK.ETHEREUM]: 'ethereum',
  [NETWORK.ETHEREUM_SEPOLIA]: 'ethereum-sepolia',
  [NETWORK.POLYGON]: 'polygon',
  [NETWORK.POLYGON_MUMBAI]: 'polygon-mumbai',
  [NETWORK.AVALANCHE]: 'avalanche',
  [NETWORK.AVALANCHE_FUJI]: 'avalanche-fuji',
  [NETWORK.ARBITRUM_ONE]: 'arbitrum',
  [NETWORK.ARBITRUM_ONE_SEPOLIA]: 'arbitrum-sepolia',
  [NETWORK.ARBITRUM_NOVA]: 'arbitrum-nova',
}

export const generateAddressLink = (chainId: NETWORK, address: string) => {
  return `https://${TX_BASE_URL_FOR_CHAIN_ID[chainId]}/address/${address}`;
};

export const generateTxLink = (chainId: NETWORK, transactionHash: string) => {
  return `https://${TX_BASE_URL_FOR_CHAIN_ID[chainId]}/tx/${transactionHash}`;
};


export const TX_BASE_URL_FOR_CHAIN_ID = {
  [NETWORK.ETHEREUM]: "etherscan.io",
  [NETWORK.ETHEREUM_SEPOLIA]: "sepolia.etherscan.io",
  [NETWORK.POLYGON]: "polygonscan.com",
  [NETWORK.POLYGON_MUMBAI]: "mumbai.polygonscan.com",
  [NETWORK.AVALANCHE]: "snowtrace.io",
  [NETWORK.AVALANCHE_FUJI]: "testnet.snowtrace.io",
  [NETWORK.ARBITRUM_ONE]: "arbiscan.io",
  [NETWORK.ARBITRUM_ONE_SEPOLIA]: "sepolia.arbiscan.io",
  [NETWORK.ARBITRUM_NOVA]: "nova.arbiscan.io",
};

export const RPC_URL_BY_CHAIN_ID = {
  [NETWORK.AVALANCHE]: "https://api.avax.network/ext/bc/C/rpc",
  [NETWORK.AVALANCHE_FUJI]: "https://api.avax-test.network/ext/bc/C/rpc",
  [NETWORK.ETHEREUM]: "https://rpc.ankr.com/eth/7510c1f5ffa0b2bab84141294abfb5e42c226b82fb0e40688ec1212d248119aa",
  [NETWORK.ETHEREUM_SEPOLIA]: "https://rpc.ankr.com/eth_sepolia/7510c1f5ffa0b2bab84141294abfb5e42c226b82fb0e40688ec1212d248119aa",
  [NETWORK.POLYGON]: "https://rpc.ankr.com/polygon/7510c1f5ffa0b2bab84141294abfb5e42c226b82fb0e40688ec1212d248119aa",
  [NETWORK.POLYGON_MUMBAI]: "https://rpc.ankr.com/polygon_mumbai/7510c1f5ffa0b2bab84141294abfb5e42c226b82fb0e40688ec1212d248119aa",
  [NETWORK.ARBITRUM_ONE]: "https://arb1.arbitrum.io/rpc",
  [NETWORK.ARBITRUM_ONE_SEPOLIA]: "https://sepolia-rollup.arbitrum.io/rpc",
  [NETWORK.ARBITRUM_NOVA]: "https://nova.arbitrum.io/rpc",
}

export const truncateAddress = (address: string) => {
  return `${address.slice(0, 6)}…${address.slice(-4)}`;
}
